import React from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import { Button, Col, Input, Row, Typography } from 'antd';
import DebounceInput from 'react-debounce-input';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

import { withUser, withLayout } from '../../../hocs';
import LIST_BEARINGS from './ListBearings.query.graphql';
import Bearings from './components/Bearings';

const PAGE_SIZE = 25;


function getSearch(query) {
  const parsed = qs.parse(query, { ignoreQueryPrefix: true });
  return parsed['search'] || '';
}
function calculateOffset(page, pageSize) {
  return Math.ceil((+page - 1) * pageSize);
}

function ListBearings({
  history,
  match: {
    params: { page = '1' },
  },
  location: { search: query },
}) {
  const search = getSearch(query);
  const offset = calculateOffset(page, PAGE_SIZE);
  return (
    <React.Fragment>
      <Typography.Title level={2}>Cuscinetti</Typography.Title>
      <Query query={LIST_BEARINGS} variables={{ search, options: { limit: PAGE_SIZE, offset } }}>
        {({ loading, error, refetch, data }) => {
          return (
            <React.Fragment>
              <Row className="operations" gutter={16}>
                <Col span={12}>
                  <Button type="primary" size="large">
                    <Link to={'/bearings/add'}>Aggiungi</Link>
                  </Button>
                </Col>
                <Col span={4} offset={8}>
                  <DebounceInput
                    minLength={2}
                    debounceTimeout={500}
                    size="large"
                    placeholder="Ricerca"
                    type="text"
                    element={Input}
                    autoFocus
                    value={search}
                    onChange={event => history.replace(`/bearings/list?search=${event.target.value}`)}
                  />
                </Col>
              </Row>
              <Bearings
                data={data}
                loading={loading}
                refetch={refetch}
                error={error}
                offset={offset}
                page={page}
                pageSize={PAGE_SIZE}
              />
            </React.Fragment>
          );
        }}
      </Query>
    </React.Fragment>
  );
}

ListBearings.defaultProps = {};
ListBearings.propTypes = {};
export default compose(
  withLayout(),
  withRouter,
  withUser
)(ListBearings);
