import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { withUser } from '../hocs';

/**
 * Protect route checking if user is authenticated
 * @param user
 * @param permissions
 * @param Component
 * @param rest
 * @constructor
 */
function RoutePrivate({ permissions = [], user, location, ...rest }) {
  if (!user) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />
    );
  }
  if (permissions.length === 0) {
    return (
      <Route {...rest} />
    );
  }
  if (permissions.some(permission => user.roles.includes(permission))) {
    return (
      <Route {...rest} />
    );
  }
  return (
    <Redirect
      to={{
        pathname: '/',
        state: { from: location },
      }}
    />
  );
  // return (
  //   <Route {...rest} />
  // );
  // return (
  //   <Choose>
  //     <When condition={user.user && permissions}>
  //       <Choose>
  //         <When
  //           condition={permissions.some(permission =>
  //             user.user.user.permissions.includes(permission)
  //           )}
  //         >
  //           <Route {...rest} />
  //         </When>
  //         <Otherwise>
  //           <Redirect
  //             to={{
  //               pathname: '/errors/401',
  //               state: { from: location },
  //             }}
  //           />
  //         </Otherwise>
  //       </Choose>
  //     </When>
  //     <When condition={user.user}>
  //       <Route {...rest} />
  //     </When>
  //     <Otherwise>
  //       <Redirect
  //         to={{
  //           pathname: '/errors/401',
  //           state: { from: location },
  //         }}
  //       />
  //     </Otherwise>
  //   </Choose>
  // );
}

RoutePrivate.defaultProps = {};
RoutePrivate.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
};

export default compose(
  withUser,
  withRouter
)(RoutePrivate);
