import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import { RoutePrivate } from './components';
import * as Views from './views';
import * as Bearings from './views/bearings';
import * as PressurePlates from './views/pressure-plates';
import * as DrivenPlates from './views/driven-plates';
import * as TorsionDamper from './views/torsion-dampers';
import * as Kits from './views/kits';
import Import from './views/import';

function Routes() {
  console.log('Initializing routes...');
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Views.Home} />
        <RoutePrivate path="/administration" component={Views.Administration} permissions={['ADMINISTRATOR', 'MANAGER']} />
        <RoutePrivate path="/catalogue" component={Views.Catalogue} />
        <RoutePrivate path="/bearings/list/:page?" component={Bearings.List} />
        <RoutePrivate path="/bearings/add" component={Bearings.Create} />
        <RoutePrivate path="/bearings/edit/:bearingId" component={Bearings.Edit} />
        <RoutePrivate path="/torsion-dampers/list/:page?" component={TorsionDamper.List} />
        <RoutePrivate path="/torsion-dampers/add" component={TorsionDamper.Create} />
        <RoutePrivate path="/torsion-dampers/edit/:torsionDamperId" component={TorsionDamper.Edit} />
        <RoutePrivate path="/driven-plates/list/:page?" component={DrivenPlates.List} />
        <RoutePrivate path="/driven-plates/add" component={DrivenPlates.Create} />
        <RoutePrivate path="/driven-plates/edit/:drivenPlateId" component={DrivenPlates.Edit} />
        <RoutePrivate path="/pressure-plates/list/:page?" component={PressurePlates.List} />
        <RoutePrivate path="/pressure-plates/add" component={PressurePlates.Create} />
        <RoutePrivate path="/pressure-plates/edit/:pressurePlateId" component={PressurePlates.Edit} />
        <RoutePrivate path="/kits/list/:page?" component={Kits.List} />
        <RoutePrivate path="/kits/add" component={Kits.Create} />
        <RoutePrivate path="/kits/edit/:kitId" component={Kits.Edit} />
        <RoutePrivate path="/import/:type" component={Import} />
      </Switch>
    </Router>
  );
}

Routes.defaultProps = {};
Routes.propTypes = {};
export default Routes;
