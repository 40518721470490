import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Layout, Menu } from 'antd';

function Sidebar({ match: { params: { type }}}) {
  return (
    <Layout.Sider className="sider">
      <Menu
        defaultSelectedKeys={['cars']}
        mode="inline"
        selectedKeys={[type]}
      >
        <Menu.Item key="products">
          <NavLink to="/import/products">
            Catalogo prodotti
          </NavLink>
        </Menu.Item>
        <Menu.Item key="torsions">
          <NavLink to="/import/torsions">
            Giunti
          </NavLink>
        </Menu.Item>
        <Menu.Item key="cars">
          <NavLink to="/import/cars">
            Automobili
          </NavLink>
        </Menu.Item>
        <Menu.Item key="trucks">
          <NavLink to="/import/trucks">
            Autocarri
          </NavLink>
        </Menu.Item>
        <Menu.Item key="tractors">
          <NavLink to="/import/tractors">
            Trattori
          </NavLink>
        </Menu.Item>
        <Menu.Item key="crc">
          <NavLink to="/import/crc">
            CRC
          </NavLink>
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
}

Sidebar.defaultProps = {};
Sidebar.propTypes = {};
export default compose(
  withRouter
)(Sidebar);
