import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Mutation } from 'react-apollo';
import { Popconfirm } from 'antd';

import REMOVE_ITEM from './RemoveItem.mutation.graphql';

function RemoveItem({ onRemove, afterMutation, productId, children }) {
  return (
    <Mutation mutation={REMOVE_ITEM} variables={{ productId }}>
      {mutation => (
        <Popconfirm title="Sei sicuro?" cancelText="Annulla" onConfirm={onRemove(mutation, afterMutation)}>
          {children}
        </Popconfirm>
      )}
    </Mutation>
  );
}
RemoveItem.defaultProps = {};
RemoveItem.propTypes = {
  onRemove: PropTypes.func.isRequired,
  drivenPlateId: PropTypes.string.isRequired,
  children: PropTypes.node,
};
export default compose()(RemoveItem);
