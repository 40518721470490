/**
 * Created by andrea on 19/06/2017.
 */
import { SET_USER, SET_TOKEN } from '../actions'

const initialState = {
  user: null,
  token: null
};
/**
 * User reducer
 * @param state
 * @param action
 * @returns {*}
 */
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.value };
    case SET_TOKEN:
      return { ...state, token: action.value };
    default:
      return state
  }
};

export default userReducer