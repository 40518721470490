import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistStore } from 'redux-persist';

import client from './libs/graphql';
import store from './store';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Routes from './Routes';


const persistor = persistStore(store, null, () => {
  console.log('Store rehydrated');
});
console.log('Store to be rehydrated', persistor);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate
      persistor={persistor}
      loading={
        <React.Fragment>
          <h1>Loading...</h1>
        </React.Fragment>
      }
    >
      <ApolloProvider client={client}>
        <Routes />
      </ApolloProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
