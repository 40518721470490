import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Divider, Icon, Table } from 'antd';
import { Link } from 'react-router-dom';

import RemoveItem from './RemoveItem';
import toast from '../../../../libs/toast';

function onRemove(mutation, refetch) {
  return async function() {
    try {
      await mutation();
      await refetch();
      toast({ title: 'Record eliminato correttamente' });
    } catch (error) {
      console.error(error);
      toast({ type: 'error', title: "C'è stato un errore durante il salvataggio" });
    }
  };
}
function DrivenPlates({ loading, error, refetch, data, page, pageSize, offset, history }) {
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  const {
    drivenPlatesPaged: { docs, total },
  } = data;
  return (
    <Table
      rowKey="_id"
      dataSource={docs}
      pagination={{
        current: +page,
        pageSize,
        total,
        onChange: page => {
          history.replace(`/driven-plates/list/${page}`);
          refetch({ options: { limit: pageSize, offset } });
        },
      }}
    >
      <Table.Column title="Codice" dataIndex="code" key="code" />
      <Table.Column title="Descrizione" dataIndex="description_it" key="description_it" />
      <Table.Column title="Immagine" dataIndex="image" key="image" />
      <Table.Column title="Diametro" dataIndex="facing_size" key="facing_size" />
      <Table.Column title="Splines" dataIndex="splines" key="splines" />
      <Table.Column title="Z" dataIndex="z" key="z" />
      <Table.Column
        title="Azioni"
        dataIndex="action"
        render={(text, { _id }) => (
          <React.Fragment>
            <Link to={`/driven-plates/edit/${_id}`}>
              <Icon type="edit" /> Modifica
            </Link>
            <Divider type="vertical" />
            <RemoveItem drivenPlateId={_id} onRemove={onRemove} afterMutation={refetch}>
              <a href="#">
                <Icon type="delete" /> Elimina
              </a>
            </RemoveItem>
          </React.Fragment>
        )}
      />
    </Table>
  );
}

DrivenPlates.defaultProps = {};
DrivenPlates.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape({
    drivenPlatesPaged: PropTypes.shape({
      docs: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          code: PropTypes.string.isRequired,
          description_it: PropTypes.string,
          image: PropTypes.string,
          facing_size: PropTypes.string,
          splines: PropTypes.string,
          z: PropTypes.string,
        })
      ).isRequired,
      total: PropTypes.number.isRequired,
    }),
  }).isRequired,
  page: PropTypes.string.isRequired,
  pageSize: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
};
export default compose(withRouter)(DrivenPlates);
