import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Icon, Input, Button, Typography } from 'antd';
import axios from 'axios';
import styled from 'styled-components';

import { setUser, setToken } from '../../../actions';
import toast from '../../../libs/toast';
import Divider from 'antd/es/divider';

const API = process.env.REACT_APP_API;

const StyledContainer = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
`;

class LoginForm extends React.Component {
  handleSubmit = async e => {
    e.preventDefault();
    const { form, dispatch, history } = this.props;
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          const {
            data: { token, user },
          } = await axios.post(`${API}/api/v1/authentication/sign`, values);
          toast({ title: 'Benvenuto' });
          dispatch(setUser(user));
          dispatch(setToken(token));
          history.push('/driven-plates/list');
        } catch (error) {
          console.log('Error received', JSON.stringify(error));

          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            toast({
              type: 'warning',
              title: error.response.data.message,
            });
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            toast({
              type: 'error',
              title: "C'è stato un errore, riprova più tardi",
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            toast({
              type: 'warning',
              title: "C'è stato un errore, riprova più tardi",
            });
          }
          console.log(error.config);
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <StyledContainer>
        <Form onSubmit={this.handleSubmit}>
          <Typography.Title level={2}>Inserisci le credenziali</Typography.Title>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                { required: true, message: 'Campo obbligatorio' },
                { type: 'email', message: 'Non è un indirizzo valido' },
              ],
            })(
              <Input
                type="email"
                size="large"
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Indirizzo email"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Inserisci la password' }],
            })(
              <Input
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                size="large"
                type="password"
                placeholder="Password"
              />
            )}
          </Form.Item>
          <Divider />
          <Form.Item style={{ marginTop: 16 }}>
            <Button type="primary" htmlType="submit" size="large">
              <Icon type="login" /> Entra
            </Button>
          </Form.Item>
        </Form>
      </StyledContainer>
    );
  }
}

export default compose(
  connect(),
  withRouter,
  Form.create({ name: 'login' })
)(LoginForm);
