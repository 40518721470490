import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { connect } from 'react-redux';

function mapStateToProps({ user }) {
  return user;
}

const withState = connect(
  mapStateToProps
);
function HasPermission({ permissions, user, children }) {
  // console.log('HasPermission', user, permissions)
  if (user && permissions.some(permission => user.roles.includes(permission))) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return <span />;
}

HasPermission.defaultProps = {
  permissions: [],
};
HasPermission.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.any,
};

export default compose(
  withState
)(HasPermission);
