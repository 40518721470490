import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

import { Button, Col, Row } from 'antd';
import { Query } from 'react-apollo';
import Videos from './components/Videos';
import LIST_VIDEOS from './Video.query.graphql';


function Video() {
  return (
    <React.Fragment>
      <Query query={LIST_VIDEOS}>
        {({ loading, error, refetch, data }) => {
          return (
            <React.Fragment>
              <Row className="operations" gutter={16}>
                <Col span={12}>
                  <Button type="primary" size="large">
                    <Link to={'/administration/videos/add'}>Aggiungi</Link>
                  </Button>
                </Col>
              </Row>
              <Videos
                data={data}
                loading={loading}
                refetch={refetch}
                error={error}
              />
            </React.Fragment>
          );
        }}
      </Query>
    </React.Fragment>
  );
}

Video.defaultProps = {};
Video.propTypes = {};
export default compose(
)(Video);
