import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import DefaultLayout from '../DefaultLayout';
import Tabs from './components/Tabs'

function AdministrationLayout({ children }) {
  return (
    <DefaultLayout>
      <Tabs />
      {children}
    </DefaultLayout>
  );
}

AdministrationLayout.defaultProps = {};
AdministrationLayout.propTypes = {
  children: PropTypes.node,
};

export default compose(

)(AdministrationLayout);
