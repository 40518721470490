import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Form, Icon, Button, Divider, Row, Col, Input, Typography } from 'antd';
import { graphql, Query } from 'react-apollo';

import GET_DOWNLOAD from './EditDownload.query.graphql';
import EDIT_DOWNLOAD from './EditDownload.mutation.graphql';
import toast from '../../../../libs/toast';

class EditDownloadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: undefined,
    };
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { form, editDownload, history, match: { params: { configurationId }, }, } = this.props;
    form.validateFieldsAndScroll(async (err, configuration) => {
      if (!err) {
        console.log('Received values of form: ', configurationId);
        try {
          await editDownload({ variables: { configurationId: configurationId, configuration: { ...configuration, image: this.state.file } } });
          toast({ title: 'Dati salvati correttamente' });
          history.push('/administration/downloads/list');
        } catch (error) {
          toast({ type: 'error', title: 'Errore durante il salvataggio dei dati' });
          console.error(error);
        }
      }
    });
  }
  getTitle(key) {
    switch (key) {
      case 'download:cars:cover':
        return <span>Copertina autovetture</span>;
      case 'download:cars:file':
        return <span>Catalogo autovetture</span>;
      case 'download:tractors:cover':
        return <span>Copertina trattori</span>;
      case 'download:tractors:file':
        return <span>Catalogo trattori</span>;
      case 'download:trucks:cover':
        return <span>Copertina autocarri</span>;
      case 'download:trucks:file':
        return <span>Catalogo autocarri</span>;
      case 'download:racing:cover':
        return <span>Copertina racing</span>;
      case 'download:racing:file':
        return <span>Catalogo racing</span>;
      case 'download:company:file':
        return <span>Profilo aziendale</span>;
      default:
        return <span>Non riconosciuto ({key})</span>;
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row>
        <Col lg={{ span: 8, offset: 8 }} md={24}>
          <Query query={GET_DOWNLOAD} variables={{ configurationId: this.props.match.params.configurationId }}>
            {({ loading, error, data }) => {
              if (loading) return <p>Loading...</p>;
              if (error) return <p>Error :(</p>;

              return (
                <Form onSubmit={this.handleSubmit.bind(this)}>
                  <Typography.Title level={2}>
                    {this.getTitle(data.configuration.key)}
                  </Typography.Title>

                  {getFieldDecorator('key', { initialValue: data.configuration.key })(
                    <Input type="hidden" />
                  )}
                  <Form.Item label="Allegato">
                    <input
                      type="file"
                      onChange={({
                        target: {
                          validity,
                          files: [file],
                        },
                      }) => validity.valid && this.setState({ file })}
                    />
                  </Form.Item>
                  <Divider />
                  <div className="operations">
                    <Button htmlType="button" size="large">
                      <Link to="/administration/downloads/list">
                        <Icon type="arrow-left" /> Indietro
                      </Link>
                    </Button>
                    <Button type="primary" htmlType="submit" size="large">
                      <Icon type="save" /> Salva
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Query>
        </Col>
      </Row>
    );
  }
}

export default compose(
  connect(),
  withRouter,
  graphql(EDIT_DOWNLOAD, {
    name: 'editDownload',
  }),
  Form.create({ name: 'edit-download' })
)(EditDownloadForm);
