import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Form, Icon,  Button, Checkbox, Divider, Row, Col } from 'antd';
import { graphql, Query } from 'react-apollo';

import GET_BACKGROUND from './EditBackground.query.graphql';
import EDIT_BACKGROUND from './EditBackground.mutation.graphql';
import toast from '../../../../libs/toast';

class EditBackgroundForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: undefined,
    };
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { form, editBackground, match: { params: { configurationId }, }, } = this.props;
    form.validateFieldsAndScroll(async (err, configuration) => {
      if (!err) {
        console.log('Received values of form: ', configurationId);
        try {
          await editBackground({ variables: { configurationId: configurationId, configuration: { key: 'background', ...configuration, image: this.state.file } } });
          toast({ title: 'Dati salvati correttamente' });
        } catch (error) {
          toast({ type: 'error', title: 'Errore durante il salvataggio dei dati' });
          console.error(error);
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row>
        <Col lg={{ span: 8, offset: 8 }} md={24}>
          <Query query={GET_BACKGROUND} variables={{ configurationId: this.props.match.params.configurationId }}>
            {({ loading, error, data }) => {
              if (loading) return <p>Loading...</p>;
              if (error) return <p>Error :(</p>;
              console.log(data);
              return (
                <Form onSubmit={this.handleSubmit.bind(this)}>
                  <Form.Item label="Immagine">
                    {getFieldDecorator('value')(
                      <input
                        type="file"
                        onChange={({
                          target: {
                            validity,
                            files: [file],
                          },
                        }) => validity.valid && this.setState({ file })}
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('meta.enabled', { valuePropName: 'checked', initialValue: data.configuration.meta.enabled })(
                      <Checkbox>Abilitato</Checkbox>
                    )}
                  </Form.Item>
                  <Divider />
                  <div className="operations">
                    <Button htmlType="button" size="large">
                      <Link to="/administration/backgrounds/list">
                        <Icon type="arrow-left" /> Indietro
                      </Link>
                    </Button>
                    <Button type="primary" htmlType="submit" size="large">
                      <Icon type="save" /> Salva
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Query>
        </Col>
      </Row>
    );
  }
}

export default compose(
  connect(),
  withRouter,
  graphql(EDIT_BACKGROUND, {
    name: 'editBackground',
  }),
  Form.create({ name: 'edit-background' })
)(EditBackgroundForm);
