import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Icon, Table } from 'antd';
import { Link } from 'react-router-dom';

function Downloads({ loading, error, data }) {
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  const { downloads } = data;
  return (
    <Table
      rowKey="_id"
      dataSource={downloads}
    >
      <Table.Column
        title="Tipologia"
        dataIndex="key"
        key="key"
        render={(_, { key }) => {
          switch (key) {
            case 'download:torsion:cover':
              return <span>Copertina giunti</span>;
            case 'download:torsion:file':
              return <span>Catalogo giunti</span>;
            case 'download:cars:cover':
              return <span>Copertina autovetture</span>;
            case 'download:cars:file':
              return <span>Catalogo autovetture</span>;
            case 'download:tractors:cover':
              return <span>Copertina trattori</span>;
            case 'download:tractors:file':
              return <span>Catalogo trattori</span>;
            case 'download:trucks:cover':
              return <span>Copertina autocarri</span>;
            case 'download:trucks:file':
              return <span>Catalogo autocarri</span>;
            case 'download:racing:cover':
              return <span>Copertina racing</span>;
            case 'download:racing:file':
              return <span>Catalogo racing</span>;
            case 'download:company:file':
              return <span>Profilo aziendale</span>;
            default:
              return <span>Non riconosciuto ({key})</span>;
          }
        }
        }
      />
      <Table.Column
        title="Valore"
        dataIndex="value"
        key="value"
        // render={(_, { value }) => (
        //   <img src={value} height={100} alt="" />
        // )}
      />
      <Table.Column
        title="Azioni"
        dataIndex="action"
        render={(_, { _id }) => (
          <React.Fragment>
            <Link to={`/administration/downloads/edit/${_id}`}>
              <Icon type="edit" /> Modifica
            </Link>
          </React.Fragment>
        )}
      />
    </Table>
  );
}

Downloads.defaultProps = {};
Downloads.propTypes = {
  loading: PropTypes.bool,
  downloads: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};
export default compose(
  withRouter
)(Downloads);
