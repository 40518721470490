import user from './user.reducer';

/**
 * Combine application reducers
 * @type {{user: userReducer}}
 */
const reducers = {
  user
};
export default reducers;
