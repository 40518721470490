/*
 * action types
 */
export const SET_USER = 'SET_USER';
export const SET_TOKEN = 'SET_TOKEN';

export function setUser(value = null) {
  return { type: SET_USER, value };
}
export function setToken(value = null) {
  return { type: SET_TOKEN, value };
}