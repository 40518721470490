import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from  'apollo-upload-client';
import idx from 'idx';
import store from '../store';

const GRAPHQL_API = process.env.REACT_APP_API;

const middlewareLink = setContext(() => {
  const state = store.getState();
  const token = idx(state, _ => _.user.token);
  if (token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  return { };
});

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    middlewareLink,
    // new HttpLink({
    //   uri: `${GRAPHQL_API}/graphql`,
    //   credentials: 'same-origin'
    // }),
    createUploadLink({
      uri: `${GRAPHQL_API}/graphql`,
      credentials: 'same-origin'
    }),
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  }
});

export default client;