import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Layout } from 'antd';
import Navbar from './components/Navbar';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const { Header, Content } = Layout;

export const MENU_HEIGHT = 64;

const StyledLayout = styled(Layout)`
  background: #fff;
  .not-printable {
    @media print {
      display: none;
    }
  }
  .header {
    position: fixed;
    z-index: 1;
    width: 100%;
    padding: 0;
  }
  .content {
    margin-top: ${MENU_HEIGHT}px;
    max-height: calc(100vh - ${MENU_HEIGHT}px);
    padding: 24px;
  }
  .operations {
    margin-bottom: 16px;
    button:not(:last-child) {
      margin-right: 16px;
    }
  }
`;
const Logo = styled.img`
  height: 56px;
  background: rgba(255, 255, 255, 0.2);
  margin: 4px 0;
  float: left;
`;

function DefaultLayout({ children }) {
  return (
    <StyledLayout>
      <Header className="header not-printable">
        <Link to="/kits">
          <Logo
            src={require('../../images/logo_ic.png')}
            alt="Industrial Cloud"
            style={{ height: 44, margin: 10, marginRight: 24 }}
          />
        </Link>
        <Navbar />
      </Header>
      <Content className="content">
        {children}
      </Content>
    </StyledLayout>
  );
}

DefaultLayout.defaultProps = {};
DefaultLayout.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
};

export default compose()(DefaultLayout);
