import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Divider, Icon, Table } from 'antd';
import { Link } from 'react-router-dom';

import RemoveItem from './RemoveItem';
import toast from '../../../../../libs/toast';

function onRemove(mutation, refetch) {
  return async function() {
    try {
      await mutation();
      await refetch();
      toast({ title: 'Record eliminato correttamente' });
    } catch (error) {
      console.error(error);
      toast({ type: 'error', title: "C'è stato un errore durante il salvataggio" });
    }
  };
}
function Backgrounds({ loading, error, refetch, data }) {
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  const { backgrounds } = data;
  return (
    <Table
      rowKey="_id"
      dataSource={backgrounds}
    >
      <Table.Column
        title="Valore"
        dataIndex="value"
        key="value"
        render={(_, { value }) => (
          <img src={value} height={100} alt="" />
        )}
      />
      <Table.Column
        title="Abilitato"
        dataIndex="value"
        key="value"
        render={(_, { meta: { enabled } }) => (
          <span>{enabled ? 'Sì' : 'No'} </span>
        )}
      />
      <Table.Column
        title="Azioni"
        dataIndex="action"
        render={(_, { _id }) => (
          <React.Fragment>
            <Link to={`/administration/backgrounds/edit/${_id}`}>
              <Icon type="edit" /> Modifica
            </Link>
            <Divider type="vertical" />
            <RemoveItem configurationId={_id} onRemove={onRemove} afterMutation={refetch}>
              <a href="#">
                <Icon type="delete" /> Elimina
              </a>
            </RemoveItem>
          </React.Fragment>
        )}
      />
    </Table>
  );
}

Backgrounds.defaultProps = {};
Backgrounds.propTypes = {
  loading: PropTypes.bool,
  backgrounds: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      meta: PropTypes.shape({
        enabled: PropTypes.bool.isRequired,
      })
    })
  ).isRequired,
};
export default compose(
  withRouter
)(Backgrounds);
