import React from 'react';
import { compose } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { Form, Icon, Input, Select, Button, Divider, Row, Col } from 'antd';
import { graphql } from 'react-apollo';

import ADD_VIDEO from './CreateVideo.mutation.graphql';
import toast from '../../../../libs/toast';

class CreateVideoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: undefined,
    };
  }
  async handleSubmit(e) {
    e.preventDefault();
    const { form, addVideo, history } = this.props;
    form.validateFieldsAndScroll(async (err, configuration) => {
      if (!err) {
        console.log('Received values of form: ', configuration);
        try {
          // await addConfiguration({ variables: { configuration: { image: this.state.file, ...configuration } } });
          await addVideo({ variables: { configuration } });
          toast({ title: 'Dati salvati correttamente' });
          history.push('/administration/videos/list');
        } catch (error) {
          toast({ type: 'error', title: 'Errore durante il salvataggio dei dati' });
          console.error(error);
        }
      }
    });
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row>
        <Col lg={{ span: 8, offset: 8 }} md={24}>
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <Form.Item label="Tipologia">
              {getFieldDecorator('key', {
                rules: [ { required: true, message: 'Campo obbligatorio', } ],
              })(
                <Select>
                  <Select.Option value="video:advertising">Pubblicità</Select.Option>
                  <Select.Option value="video:exposition">Fiere</Select.Option>
                  <Select.Option value="video:production">Produzione</Select.Option>
                  {/*<Select.Option value="background">Background</Select.Option>*/}
                  {/*<Select.Option value="download:cars">Catalogo Autovetture</Select.Option>*/}
                  {/*<Select.Option value="Catalogue:Tractors">Catalogo trattori</Select.Option>*/}
                  {/*<Select.Option value="Catalogue:Trucks">Catalogo autocarri</Select.Option>*/}
                  {/*<Select.Option value="Catalogue:Racing">Catalogo racing</Select.Option>*/}
                  {/*<Select.Option value="Catalogue:Company">Profilo aziendale</Select.Option>*/}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Link">
              {getFieldDecorator('value', {
                rules: [
                  { required: true, message: 'Campo obbligatorio', },
                  { type: 'url', message: 'Non è un link valido', },
                ],
              })(
                <Input type="text" size="large" placeholder="Inserisci link" />
              )}
            </Form.Item>
            <Form.Item label="Titolo It">
              {getFieldDecorator('meta.title_it', {
                rules: [
                  { required: true, message: 'Campo obbligatorio', }
                ],
              })(
                <Input size="large" placeholder="Inserisci titolo" />
              )}
            </Form.Item>
            <Form.Item label="Titolo En">
              {getFieldDecorator('meta.title_en', {
                rules: [
                  { required: true, message: 'Campo obbligatorio', }
                ],
              })(
                <Input size="large" placeholder="Inserisci titolo" />
              )}
            </Form.Item>
            <Form.Item label="Descrizione It">
              {getFieldDecorator('meta.description_it', {
                rules: [
                  { required: true, message: 'Campo obbligatorio', }
                ],
              })(
                <Input.TextArea size="large" placeholder="Inserisci descrizione" />
              )}
            </Form.Item>
            <Form.Item label="Descrizione End">
              {getFieldDecorator('meta.description_en', {
                rules: [
                  { required: true, message: 'Campo obbligatorio', }
                ],
              })(
                <Input.TextArea size="large" placeholder="Inserisci descrizione" />
              )}
            </Form.Item>
            {/*<Form.Item label="Immagine">*/}
            {/*  <input*/}
            {/*    type="file"*/}
            {/*    onChange={({*/}
            {/*      target: {*/}
            {/*        validity,*/}
            {/*        files: [file],*/}
            {/*      },*/}
            {/*    }) => validity.valid && this.setState({ file })}*/}
            {/*  />*/}
            {/*</Form.Item>*/}
            <Divider />
            <div className="operations">
              <Button htmlType="button" size="large">
                <Link to="/administration/videos/list">
                  <Icon type="arrow-left" /> Indietro
                </Link>
              </Button>
              <Button type="primary" htmlType="submit" size="large">
                <Icon type="save" /> Salva
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    );
  }
}

export default compose(
  withRouter,
  graphql(ADD_VIDEO, {
    name: 'addVideo',
  }),
  Form.create({ name: 'add-video' })
)(CreateVideoForm);
