import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Form, Icon, Input, Button, Typography, Divider, Row, Col } from 'antd';
import { graphql } from 'react-apollo';

import ADD_DRIVEN_PLATE from './CreateDrivenPlate.mutation.graphql';
import { withLayout } from '../../../hocs';
import toast from '../../../libs/toast';

class DrivenPlateCreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: undefined,
    };
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { form, addProduct, history, } = this.props;
    form.validateFieldsAndScroll(async (err, product) => {
      if (!err) {
        console.log('Received values of form: ', product);
        try {
          await addProduct({ variables: { type: 'DrivenPlate', product: { image: this.state.file, ...product } } });
          toast({ title: 'Dati salvati correttamente' });
          history.push('/driven-plates/list');
        } catch (error) {
          toast({ type: 'error', title: 'Errore durante il salvataggio dei dati' });
          console.error(error);
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row>
        <Col lg={{ span: 8, offset: 8 }} md={24}>
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <Typography.Title level={2}>Anagrafica disco frizione <small>(nuovo)</small></Typography.Title>
            <Form.Item label="Codice">
              {getFieldDecorator('code', {
                rules: [ { required: true, message: 'Campo obbligatorio', } ],
              })(
                <Input type="text" size="large" placeholder="Codice" />
              )}
            </Form.Item>
            <Form.Item label="Parametro">
              {getFieldDecorator('parameter')(
                <Input type="text" size="large" placeholder="Parametro" />
              )}
            </Form.Item>
            <Form.Item label="Diametro">
              {getFieldDecorator('meta.facing_size')(
                <Input type="text" size="large" placeholder="Diametro" />
              )}
            </Form.Item>
            <Form.Item label="Splines">
              {getFieldDecorator('meta.splines')(
                <Input type="text" size="large" placeholder="Splines" />
              )}
            </Form.Item>
            <Form.Item label="Z">
              {getFieldDecorator('meta.z')(
                <Input type="text" size="large" placeholder="z" />
              )}
            </Form.Item>
            <Form.Item label="Montato con">
              {getFieldDecorator('meta.mounted_with')(
                <Input type="text" size="large" placeholder="Montato con..." />
              )}
            </Form.Item>
            <Form.Item label="Numero piastrine sinterizzate">
              {getFieldDecorator('meta.piastrine')(
                <Input type="text" size="large" placeholder="Numero piastrine sinterizzate" />
              )}
            </Form.Item>
            <Form.Item label="Dimensione piastrine sinterizzate">
              {getFieldDecorator('meta.cxfacing')(
                <Input type="text" size="large" placeholder="Dimensione piastrine sinterizzate" />
              )}
            </Form.Item>
            <Form.Item label="Inches">
              {getFieldDecorator('meta.inc')(
                <Input type="text" size="large" placeholder="Inches" />
              )}
            </Form.Item>
            <Form.Item label="Descrizione IT">
              {getFieldDecorator('description_it', { initialValue: 'Disco frizione' })(
                <Input.TextArea rows={3} placeholder="Descrizione" />
              )}
            </Form.Item>
            <Form.Item label="Descrizione EN">
              {getFieldDecorator('description_en', { initialValue: 'Clutch disc' })(
                <Input.TextArea rows={3} placeholder="Descrizione" />
              )}
            </Form.Item>
            <Form.Item label="Immagine">
              <input
                type="file"
                onChange={({
                  target: {
                    validity,
                    files: [file],
                  },
                }) => validity.valid && this.setState({ file })}
              />
            </Form.Item>
            <Divider />
            <div className="operations">
              <Button htmlType="button" size="large">
                <Link to="/driven-plates/list">
                  <Icon type="arrow-left" /> Indietro
                </Link>
              </Button>
              <Button type="primary" htmlType="submit" size="large">
                <Icon type="save" /> Salva
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    );
  }
}

export default compose(
  connect(),
  withLayout(),
  withRouter,
  graphql(ADD_DRIVEN_PLATE, {
    name: 'addProduct',
  }),
  Form.create({ name: 'add-driven-plate' })
)(DrivenPlateCreateForm);
