import React from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

import { Button, Col, Row } from 'antd';
import { Query } from 'react-apollo';
import Backgrounds from './components/Backgrounds';
import LIST_BACKGROUNDS from './Background.query.graphql';


function Background() {
  return (
    <React.Fragment>
      <Query query={LIST_BACKGROUNDS}>
        {({ loading, error, refetch, data }) => {
          return (
            <React.Fragment>
              <Row className="operations" gutter={16}>
                <Col span={12}>
                  <Button type="primary" size="large">
                    <Link to={'/administration/backgrounds/add'}>Aggiungi</Link>
                  </Button>
                </Col>
              </Row>
              <Backgrounds
                data={data}
                loading={loading}
                refetch={refetch}
                error={error}
              />
            </React.Fragment>
          );
        }}
      </Query>
    </React.Fragment>
  );
}

Background.defaultProps = {};
Background.propTypes = {};
export default compose(
)(Background);
