import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Form, Icon, Input, Button, Typography, Divider, Row, Col } from 'antd';
import { graphql, Query } from 'react-apollo';
import idx from 'idx';

import GET_KIT from './EditKit.query.graphql';
import EDIT_KIT from './EditKit.mutation.graphql';

import BEARINGS from './Bearings.query.graphql';
import DRIVEN_PLATES from './DrivenPlates.query.graphql';
import PRESSURE_PLATES from './PressurePlates.query.graphql';

import { withLayout } from '../../../hocs';
import toast from '../../../libs/toast';
import RemoteSelect from '../../../components/RemoteSelect';

class KitEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: undefined,
    };
  }
  async handleSubmit(e) {
    e.preventDefault();
    const { form, editProduct, match: { params: { kitId }, }, } = this.props;
    form.validateFieldsAndScroll(async (err, product) => {
      if (!err) {
        console.log('Received values of form: ', kitId, product);
        try {
          await editProduct({ variables: { productId: kitId, product: { image: this.state.file, ...product } } });
          toast({ title: 'Dati salvati correttamente' });
        } catch (error) {
          toast({ type: 'error', title: 'Errore durante il salvataggio dei dati' });
          console.error(error);
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row>
        <Col lg={{ span: 8, offset: 8 }} md={24}>
          <Query query={GET_KIT} variables={{ kitId: this.props.match.params.kitId }}>
            {({ loading, error, data }) => {
              if (loading) return <p>Loading...</p>;
              if (error) return <p>Error :(</p>;
              const { kit } = data;
              return (
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <Typography.Title level={2}>Modifica kit</Typography.Title>
            <Form.Item label="Codice">
              {getFieldDecorator('code', {
                rules: [ { required: true, message: 'Campo obbligatorio', } ],
                initialValue: kit.code
              })(
                <Input type="text" size="large" placeholder="Codice" />
              )}
            </Form.Item>
            <Form.Item label="Descrizione IT">
              {getFieldDecorator('description_it', { initialValue: kit.description_it })(
                <Input.TextArea rows={3} placeholder="Descrizione" />
              )}
            </Form.Item>
            <Form.Item label="Descrizione EN">
              {getFieldDecorator('description_en', { initialValue: kit.description_en })(
                <Input.TextArea rows={3} placeholder="Descrizione" />
              )}
            </Form.Item>
            <RemoteSelect
              form={this.props.form}
              name="meta.driven_plate"
              label="Disco frizione"
              initialValue={idx(kit, _ => _.driven_plate.code)}
              query={DRIVEN_PLATES}
              dataName="drivenPlatesPaged"
            />
            <RemoteSelect
              form={this.props.form}
              name="meta.pressure_plate"
              label="Meccanismo"
              initialValue={idx(kit, _ => _.pressure_plate.code)}
              query={PRESSURE_PLATES}
              dataName="pressurePlatesPaged"
            />
            <RemoteSelect
              form={this.props.form}
              name="meta.bearing"
              label="Cuscinetto"
              initialValue={idx(kit, _ => _.bearing.code)}
              query={BEARINGS}
              dataName="bearingsPaged"
            />
            <Form.Item label="Immagine">
              <input
                type="file"
                onChange={({
                  target: {
                    validity,
                    files: [file],
                  },
                }) => validity.valid && this.setState({ file })}
              />
            </Form.Item>
            <Divider />
            <div className="operations">
              <Button htmlType="button" size="large">
                <Link to="/kits/list">
                  <Icon type="arrow-left" /> Indietro
                </Link>
              </Button>
              <Button type="primary" htmlType="submit" size="large">
                <Icon type="save" /> Salva
              </Button>
            </div>
          </Form>
                )}}
          </Query>
        </Col>
      </Row>
    );
  }
}

export default compose(
  connect(),
  withLayout(),
  withRouter,
  graphql(EDIT_KIT, {
    name: 'editProduct',
  }),
  Form.create({ name: 'edit-kit' })
)(KitEditForm);
