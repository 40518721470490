import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { compose } from 'redux';
import {Icon, Typography} from 'antd';
import Dropzone from 'react-dropzone';

import UPLOAD from './UploadCatalogue.mutation.graphql';
import toast from '../../../libs/toast';

class UploadCatalogue extends Component {
  state = {
    loading: false,
    result: undefined,
  };
  toggle = (value) => {
    this.setState({ loading: value });
  };
  async handleUpload(acceptedFiles) {
    if (acceptedFiles.length === 1) {
      const file = acceptedFiles[0];
      this.toggle(true);
      this.setState({ result: undefined });
      try {
        const { type, upload } = this.props;
        console.log('uploading', type, file)
        const { data: { uploadApplication: result }} = await upload({ variables: { type, file } });
        toast({ title: 'Dati salvati correttamente' });
        this.setState({ result });

      } catch (error) {
        toast({ type: 'error', title: 'Errore durante il salvataggio dei dati' });
        console.error(error);
      }
    }
    this.toggle(false);
  }
  render() {
    const { result } = this.state;
    console.log('result', result);
    return (
      <React.Fragment>
        <Typography.Title level={4}>
          Carica il catalogo ufficiale per la categoria (AUTO-2017.xlsx, TRACTOR-2019.xlsx, TRUCK-2018, etc)
        </Typography.Title>
        <Dropzone
          onDrop={this.handleUpload.bind(this)}
          multiple={false}
          accept={['.xlsx', '.xls']}
        >
          {({getRootProps, getInputProps}) => {
            return (
              <a {...getRootProps()}>
                <input {...getInputProps()} />
                <Icon type="upload" /> Carica
              </a>
            )
          }}
        </Dropzone>
        {result && (
          <div>
            Status: <strong>{result.status ? 'OK' : 'KO'}</strong><br />
            Total: <strong>{result.total}</strong><br />
            Success: <strong>{result.success}</strong><br />
            {result.errors && result.errors.length > 0 && <span>Errors: <ul>{result.errors.map(error => (<li>Line: {error}</li>))}</ul></span>}
          </div>
        )}
      </React.Fragment>
    );
  }
}

UploadCatalogue.defaultProps = {};
UploadCatalogue.propTypes = {
  type: PropTypes.oneOf(['EXCAVATOR', 'CAR', 'TRUCK', 'TRACTOR'])
};
export default compose(
  graphql(UPLOAD, { name: 'upload' })
)(UploadCatalogue);
