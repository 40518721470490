import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Form, Icon, Input, Button, Typography, Divider, Row, Col } from 'antd';
import { graphql, Query } from 'react-apollo';

import GET_TORSION_DAMPER from './EditTorsionDamper.query.graphql';
import EDIT_TORSION_DAMPER from './EditTorsionDamper.mutation.graphql';
import { withLayout } from '../../../hocs';
import toast from '../../../libs/toast';

class EditTorsionDamperForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: undefined,
    };
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { form, editProduct, match: { params: { torsionDamperId }, }, } = this.props;
    form.validateFieldsAndScroll(async (err, product) => {
      if (!err) {
        console.log('Received values of form: ', torsionDamperId, product);
        try {
          await editProduct({ variables: { productId: torsionDamperId, product: { image: this.state.file, ...product } } });
          toast({ title: 'Dati salvati correttamente' });
        } catch (error) {
          toast({ type: 'error', title: 'Errore durante il salvataggio dei dati' });
          console.error(error);
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row>
        <Col lg={{ span: 8, offset: 8 }} md={24}>
          <Query query={GET_TORSION_DAMPER} variables={{ torsionDamperId: this.props.match.params.torsionDamperId }}>
            {({ loading, error, data }) => {
              if (loading) return <p>Loading...</p>;
              if (error) return <p>Error :(</p>;
              const { torsionDamper } = data;
              return (
                <Form onSubmit={this.handleSubmit.bind(this)}>
                  <Typography.Title level={2}>Anagrafica giunto torsionale</Typography.Title>
                  <Form.Item label="Codice">
                    {getFieldDecorator('code', {
                      rules: [ { required: true, message: 'Campo obbligatorio', } ],
                      initialValue: torsionDamper.code
                    })(
                      <Input type="text" size="large" placeholder="Codice" />
                    )}
                  </Form.Item>
                  <Form.Item label="Parametro">
                    {getFieldDecorator('parameter', { initialValue: torsionDamper.parameter })(
                      <Input type="text" size="large" placeholder="Parametro" />
                    )}
                  </Form.Item>
                  <Form.Item label="Diametro">
                    {getFieldDecorator('meta.facing_size', { initialValue: torsionDamper.facing_size })(
                      <Input type="text" size="large" placeholder="Diametro" />
                    )}
                  </Form.Item>
                  <Form.Item label="Splines">
                    {getFieldDecorator('meta.splines', { initialValue: torsionDamper.splines })(
                      <Input type="text" size="large" placeholder="Splines" />
                    )}
                  </Form.Item>
                  <Form.Item label="Z">
                    {getFieldDecorator('meta.z', { initialValue: torsionDamper.z })(
                      <Input type="text" size="large" placeholder="z" />
                    )}
                  </Form.Item>
                  <Form.Item label="Montato con">
                    {getFieldDecorator('meta.mounted_with', { initialValue: torsionDamper.mounted_with })(
                      <Input type="text" size="large" placeholder="Montato con..." />
                    )}
                  </Form.Item>
                  <Form.Item label="Numero piastrine sinterizzate">
                    {getFieldDecorator('meta.piastrine', { initialValue: torsionDamper.piastrine })(
                      <Input type="text" size="large" placeholder="Numero piastrine sinterizzate" />
                    )}
                  </Form.Item>
                  <Form.Item label="Dimensione piastrine sinterizzate">
                    {getFieldDecorator('meta.cxfacing', { initialValue: torsionDamper.cxfacing })(
                      <Input type="text" size="large" placeholder="Dimensione piastrine sinterizzate" />
                    )}
                  </Form.Item>
                  <Form.Item label="Inches">
                    {getFieldDecorator('meta.inc', { initialValue: torsionDamper.inc })(
                      <Input type="text" size="large" placeholder="Inches" />
                    )}
                  </Form.Item>
                  <Form.Item label="Descrizione IT">
                    {getFieldDecorator('description_it', { initialValue: torsionDamper.description_it })(
                      <Input.TextArea rows={3} placeholder="Descrizione" />
                    )}
                  </Form.Item>
                  <Form.Item label="Descrizione EN">
                    {getFieldDecorator('description_en', { initialValue: torsionDamper.description_en })(
                      <Input.TextArea rows={3} placeholder="Descrizione" />
                    )}
                  </Form.Item>
                  <Form.Item label="Immagine">
                    <input
                      type="file"
                      onChange={({
                        target: {
                          validity,
                          files: [file],
                        },
                      }) => validity.valid && this.setState({ file })}
                    />
                  </Form.Item>
                  <Divider />
                  <div className="operations">
                    <Button htmlType="button" size="large">
                      <Link to="/torsion-dampers/list">
                        <Icon type="arrow-left" /> Indietro
                      </Link>
                    </Button>
                    <Button type="primary" htmlType="submit" size="large">
                      <Icon type="save" /> Salva
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Query>
        </Col>
      </Row>
    );
  }
}

export default compose(
  connect(),
  withLayout(),
  withRouter,
  graphql(EDIT_TORSION_DAMPER, {
    name: 'editProduct',
  }),
  Form.create({ name: 'edit-driven-plate' })
)(EditTorsionDamperForm);
