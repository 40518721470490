import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Divider, Icon, Table } from 'antd';
import { Link } from 'react-router-dom';

import RemoveItem from './RemoveItem';
import toast from '../../../../../libs/toast';

function onRemove(mutation, refetch) {
  return async function() {
    try {
      await mutation();
      await refetch();
      toast({ title: 'Record eliminato correttamente' });
    } catch (error) {
      console.error(error);
      toast({ type: 'error', title: "C'è stato un errore durante il salvataggio" });
    }
  };
}
function Videos({ loading, error, refetch, data }) {
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  const { videos } = data;
  return (
    <Table
      rowKey="_id"
      dataSource={videos}
    >
      <Table.Column
        title="Tipologia"
        dataIndex="key"
        key="key"
        render={(_, { key }) => {
          switch (key) {
            case 'video:advertising':
              return <span>Video pubblicità</span>;
            case 'video:exposition':
              return <span>Video fiere</span>;
            case 'video:production':
              return <span>Video produzione</span>;
            // case 'background':
            //   return <span>Background</span>;
            // case 'catalogue':
            //   return <span>Catalogo</span>;
            default:
              return <span>Non riconosciuto ({key})</span>;
          }
        }
        }
      />
      <Table.Column
        title="Valore"
        dataIndex="value"
        key="value"
      />
      <Table.Column
        title="Azioni"
        dataIndex="action"
        render={(_, { _id }) => (
          <React.Fragment>
            <Link to={`/administration/videos/edit/${_id}`}>
              <Icon type="edit" /> Modifica
            </Link>
            <Divider type="vertical" />
            <RemoveItem configurationId={_id} onRemove={onRemove} afterMutation={refetch}>
              <a href="#">
                <Icon type="delete" /> Elimina
              </a>
            </RemoveItem>
          </React.Fragment>
        )}
      />
    </Table>
  );
}

Videos.defaultProps = {};
Videos.propTypes = {
  loading: PropTypes.bool,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};
export default compose(
  withRouter
)(Videos);
