import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Menu, Icon } from 'antd';
import { withApollo } from 'react-apollo';
import Swal from 'sweetalert2';

import { setUser, setToken } from '../../../actions';
import HasPermission from '../../../components/HasPermission';
import toast from '../../../libs/toast';

function Navbar({ dispatch, history, location, client }) {
  async function onLogout() {
    const result = await Swal.fire({
      title: 'Sei sicuro?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Esci',
      cancelButtonText: 'Annulla',
    });
    if (result.value) {
      try {
        dispatch(setUser());
        dispatch(setToken());
        client.resetStore();
        history.push('/');
      } catch (error) {
        console.error(error);
        toast({ type: 'error', title: "C'è stato un errore durante il logout" });
      }
    }
  }
  return (
    <Menu
      mode="horizontal"
      defaultSelectedKeys={['/']}
      selectedKeys={['/' + location.pathname.split('/')[1]]}
      style={{ lineHeight: '64px' }}
      className="not-printable"
    >
      <Menu.Item key="/driven-plates">
        <NavLink to="/driven-plates/list">
          <span>Dischi</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/torsion-dampers">
        <NavLink to="/torsion-dampers/list">
          <span>Giunti</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/pressure-plates">
        <NavLink to="/pressure-plates/list">
          <span>Meccanismi</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/bearings">
        <NavLink to="/bearings/list">
          <span>Cuscinetti</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/kits">
        <NavLink to="/kits/list">
          <span>Kits</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/catalogue">
        <NavLink to="/catalogue">
          <span>Export dati</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/import">
        <NavLink to="/import/products">
          <span>Import dati</span>
        </NavLink>
      </Menu.Item>

      <Menu.Item key="/logout" style={{ float: 'right' }} onClick={onLogout}>
        <span>
          <Icon type="logout" /> Esci
        </span>
      </Menu.Item>
      <Menu.Item key="/administration" style={{ float: 'right' }}>
        <HasPermission permissions={['ADMINISTRATOR', 'MANAGER']}>
          <NavLink to="/administration">
            <span>Amministrazione</span>
          </NavLink>
        </HasPermission>
      </Menu.Item>
    </Menu>
  );
}

Navbar.defaultProps = {};
Navbar.propTypes = {};
export default compose(
  connect(),
  withRouter,
  withApollo
)(Navbar);
