import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout/DefaultLayout.layout';

const e = React.createElement;

/**
 *
 * @param layout
 */
const withLayout = ({ layout = DefaultLayout, title = null } = { layout: DefaultLayout }) => WrappedComponent => {
  const WithLayout = props => {
    return e(layout, { title }, e(WrappedComponent, { ...props }));
  };
  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  WithLayout.displayName = `withLayout(${wrappedComponentName})`;
  return WithLayout;
};

export default withLayout;
