import React, { Component } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import axios from 'axios';
import FileSaver from 'file-saver';

import { withLayout } from '../../hocs';

class Catalogue extends Component {
  async download() {
    // this.setState({ loading: true });
    try {
      const { data } = await axios({
        method:'post',
        url: `${process.env.REACT_APP_API}/api/v1/catalogue/download`,
        responseType:'blob',
      });
      // console.log('Data', data, rest);
      const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const timestamp = new Date().getTime().toString();
      FileSaver.saveAs(blob, `catalogue-${timestamp}.xlsx`);
    } catch (error) {
      alert(error.toString());
      // Swal.fire(
      //   'Errore',
      //   JSON.stringify(error),
      //   'error'
      // )
    }
    // this.setState({ loading: false });
  }
  render() {
    return (
      <div>
        <Button htmlType="button" type="primary" onClick={this.download.bind(this)}>
          Export dati
        </Button>
      </div>
    );
  }
}

Catalogue.defaultProps = {};
Catalogue.propTypes = {};
export default compose(
  withLayout(),
  withRouter,
)(Catalogue);