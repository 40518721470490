import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { compose } from 'redux';

import * as Backgrounds from './backgrounds';
import * as Downloads from './downloads';
import * as Videos from './videos';
import AdministrationLayout from '../../layouts/AdministrationLayout';
import withLayout from '../../hocs/withLayout.hoc';

function Routes() {
  return (
    <Switch>
      <Route path="/administration" exact render={() => <Redirect to="/administration/videos/list" />} />
      <Route path="/administration/backgrounds/list" component={Backgrounds.List} />
      <Route path="/administration/backgrounds/add" component={Backgrounds.Create} />
      <Route path="/administration/backgrounds/edit/:configurationId" component={Backgrounds.Edit} />
      <Route path="/administration/downloads/list" component={Downloads.List} />
      <Route path="/administration/downloads/edit/:configurationId" component={Downloads.Edit} />
      <Route path="/administration/videos/list" component={Videos.List} />
      <Route path="/administration/videos/add" component={Videos.Create} />
      <Route path="/administration/videos/edit/:configurationId" component={Videos.Edit} />
    </Switch>
  );
}

Routes.defaultProps = {};
Routes.propTypes = {};
export default compose(
  withLayout({ layout: AdministrationLayout })
)(Routes);
