import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Divider, Icon, Table } from 'antd/lib/index';
import { Link } from 'react-router-dom';

import RemoveItem from './RemoveItem';
import toast from '../../../../libs/toast';
import idx from 'idx';

function onRemove(mutation, refetch) {
  return async function() {
    try {
      await mutation();
      await refetch();
      toast({ title: 'Record eliminato correttamente' });
    } catch (error) {
      console.error(error);
      toast({ type: 'error', title: "C'è stato un errore durante il salvataggio" });
    }
  };
}
function Kits({ loading, error, refetch, data, page, pageSize, offset, history }) {
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  const {
    kitsPaged: { docs, total },
  } = data;
  return (
    <Table
      rowKey="_id"
      dataSource={docs}
      pagination={{
        current: +page,
        pageSize,
        total,
        onChange: page => {
          history.replace(`/kits/list/${page}`);
          refetch({ options: { limit: pageSize, offset } });
        },
      }}
    >
      <Table.Column title="Codice" dataIndex="code" key="code" />
      <Table.Column title="Descrizione" dataIndex="description_it" key="description_it" />
      <Table.Column title="Immagine" dataIndex="image" key="image" />
      <Table.Column
        title="Disco"
        dataIndex="driven_plate"
        key="driven_plate"
        render={driven_plate => (<span>{idx(driven_plate, _ => _.code) || '-'}</span>)}
      />
      <Table.Column
        title="Meccanismo"
        dataIndex="pressure_plate"
        key="pressure_plate"
        render={pressure_plate => (<span>{idx(pressure_plate, _ => _.code) || '-'}</span>)}
      />
      <Table.Column
        title="Cuscinetto"
        dataIndex="bearing"
        key="bearing"
        render={bearing => (<span>{idx(bearing, _ => _.code) || '-'}</span>)}
      />
      <Table.Column
        title="Azioni"
        dataIndex="action"
        render={(text, { _id }) => (
          <React.Fragment>
            <Link to={`/kits/edit/${_id}`}>
              <Icon type="edit" /> Modifica
            </Link>
            <Divider type="vertical" />
            <RemoveItem kitId={_id} onRemove={onRemove} afterMutation={refetch}>
              <a href="#">
                <Icon type="delete" /> Elimina
              </a>
            </RemoveItem>
          </React.Fragment>
        )}
      />
    </Table>
  );
}

Kits.defaultProps = {};
Kits.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape({
    kitsPaged: PropTypes.shape({
      docs: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          code: PropTypes.string.isRequired,
          description_it: PropTypes.string,
          image: PropTypes.string,
          driven_plate: PropTypes.shape({
            _id: PropTypes.string.isRequired,
            code: PropTypes.string.isRequired,
          }),
          pressure_plate: PropTypes.shape({
            _id: PropTypes.string.isRequired,
            code: PropTypes.string.isRequired,
          }),
          bearing: PropTypes.shape({
            _id: PropTypes.string.isRequired,
            code: PropTypes.string.isRequired,
          }),
        })
      ).isRequired,
      total: PropTypes.number.isRequired,
    }),
  }).isRequired,
  page: PropTypes.string.isRequired,
  pageSize: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
};
export default compose(withRouter)(Kits);
