import React from 'react';
import { compose } from 'redux';
import { Layout, Row, Col, Typography,  } from 'antd';
import { withRouter } from 'react-router-dom';
import LoginForm from './components/LoginForm.form';
import { withUser } from '../../hocs';

function Home({ user, location }) {
  return (
    <Layout style={{ minHeight: '100vh', padding: 24 }}>
      <Row>
        <Col lg={{ span: 8, offset: 8 }} md={24}>
          <Typography.Title level={1}>
            Login
          </Typography.Title>
          <Layout.Content>
            <LoginForm/>
          </Layout.Content>
        </Col>
      </Row>
    </Layout>
  );
}

Home.defaultProps = {};
Home.propTypes = {};
export default compose(
  withRouter,
  withUser
)(Home);
