import React from 'react';
import { Menu } from 'antd';
import { NavLink, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import styled from 'styled-components';

const StyledMenu = styled(Menu)`
  margin: -24px -24px 24px -24px;
`;
function Tabs({ location }) {
  return (
    <StyledMenu
      selectedKeys={['/'+location.pathname.split('/')[1]+'/' + location.pathname.split('/')[2]]}
      mode="horizontal"
    >
      <Menu.Item key="/administration/downloads">
        <NavLink to="/administration/downloads/list">
          <span>Downloads</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/administration/backgrounds">
        <NavLink to="/administration/backgrounds/list">
          <span>Sfondi</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/administration/videos">
        <NavLink to="/administration/videos/list">
          <span>Video</span>
        </NavLink>
      </Menu.Item>
    </StyledMenu>
  );
}

export default compose(
  withRouter
)(Tabs);
