import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Spin } from 'antd/lib/index';
import debounce from 'lodash/debounce';
import { Query } from 'react-apollo/index';
import idx from 'idx';


class RemoteSelect extends React.Component {
  constructor(props) {
    super(props);
    this.updateSearch = debounce(this.updateSearch, 800);
  }
  state = {
    data: [],
    value: [],
    fetching: false,
  };
  updateSearch = (search) => {
    console.log('Searching for...', search);
    this.setState({ search });
  };
  render() {
    const { search } = this.state;
    const { query, label, name, rules, initialValue, form: { getFieldDecorator }, dataName } = this.props;
    return (
      <Query query={query} variables={{ search, options: { limit: 25 } }}>
        {({ loading, error, data }) => {
          if (error) return <p>Error :(</p>;
          const docs = idx(data, _ => _[dataName].docs) || [];
          return (
            <Form.Item label={label}>
              {getFieldDecorator(name, { rules, initialValue })(
                <Select
                  showSearch
                  placeholder="Seleziona..."
                  notFoundContent={loading ? <Spin size="small" /> : null}
                  filterOption={false}
                  onSearch={this.updateSearch}
                  size="large"
                >
                  {docs.map(d => <Select.Option key={d._id} value={d.code}>{d.code}</Select.Option>)}
                </Select>
              )}
            </Form.Item>
          )
        }}
      </Query>
    )
  }
}
RemoteSelect.defaultProps = {

};
RemoteSelect.propTypes = {
  label: PropTypes.string,
  dataName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  initialValue: PropTypes.string,
  rules: PropTypes.arrayOf(PropTypes.object),
  query: PropTypes.object,
};
export default RemoteSelect;
