import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Form, Icon, Input, Button, Typography, Divider, Row, Col } from 'antd';
import { graphql, Query } from 'react-apollo';

import GET_VIDEO from './EditVideo.query.graphql';
import EDIT_VIDEO from './EditVideo.mutation.graphql';
import toast from '../../../../libs/toast';

class EditVideoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: undefined,
    };
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { form, editVideo, match: { params: { configurationId }, }, } = this.props;
    form.validateFieldsAndScroll(async (err, configuration) => {
      if (!err) {
        console.log('Received values of form: ', configurationId, configuration);
        try {
          // await editConfiguration({ variables: { configurationId: configurationId, configuration: { image: this.state.file, ...configuration } } });
          await editVideo({ variables: { configurationId, configuration } });
          toast({ title: 'Dati salvati correttamente' });
        } catch (error) {
          toast({ type: 'error', title: 'Errore durante il salvataggio dei dati' });
          console.error(error);
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row>
        <Col lg={{ span: 8, offset: 8 }} md={24}>
          <Query query={GET_VIDEO} variables={{ configurationId: this.props.match.params.configurationId }}>
            {({ loading, error, data }) => {
              if (loading) return <p>Loading...</p>;
              if (error) return <p>Error :(</p>;
              const { configuration } = data;
              return (
                <Form onSubmit={this.handleSubmit.bind(this)}>
                  <Typography.Title level={2}>
                    {configuration.code === 'video:advertising' && <span>Pubblicità</span>}
                    {configuration.code === 'video:exposition' && <span>Fiere</span>}
                    {configuration.code === 'video:production' && <span>Produzione</span>}
                  </Typography.Title>
                  <Form.Item>
                    {getFieldDecorator('key', {
                      initialValue: configuration.key,
                    })(
                      <Input type="hidden" />
                    )}
                  </Form.Item>
                  <Form.Item label="Link">
                    {getFieldDecorator('value', {
                      initialValue: configuration.value,
                      rules: [
                        { required: true, message: 'Campo obbligatorio', },
                        { type: 'url', message: 'Non è un link valido', },
                      ],
                    })(
                      <Input type="text" size="large" placeholder="Inserisci link" />
                    )}
                  </Form.Item>
                  <Form.Item label="Titolo It">
                    {getFieldDecorator('meta.title_it', {
                      initialValue: configuration.meta.title_it,
                      rules: [
                        { required: true, message: 'Campo obbligatorio', }
                      ],
                    })(
                      <Input size="large" placeholder="Inserisci titolo" />
                    )}
                  </Form.Item>
                  <Form.Item label="Titolo En">
                    {getFieldDecorator('meta.title_en', {
                      initialValue: configuration.meta.title_en,
                      rules: [
                        { required: true, message: 'Campo obbligatorio', }
                      ],
                    })(
                      <Input size="large" placeholder="Inserisci titolo" />
                    )}
                  </Form.Item>
                  <Form.Item label="Descrizione It">
                    {getFieldDecorator('meta.description_it', {
                      initialValue: configuration.meta.description_it,
                      rules: [
                        { required: true, message: 'Campo obbligatorio', }
                      ],
                    })(
                      <Input.TextArea size="large" placeholder="Inserisci descrizione" />
                    )}
                  </Form.Item>
                  <Form.Item label="Descrizione En">
                    {getFieldDecorator('meta.description_en', {
                      initialValue: configuration.meta.description_en,
                      rules: [
                        { required: true, message: 'Campo obbligatorio', }
                      ],
                    })(
                      <Input.TextArea size="large" placeholder="Inserisci descrizione" />
                    )}
                  </Form.Item>
                  <Divider />
                  <div className="operations">
                    <Button htmlType="button" size="large">
                      <Link to="/administration/videos/list">
                        <Icon type="arrow-left" /> Indietro
                      </Link>
                    </Button>
                    <Button type="primary" htmlType="submit" size="large">
                      <Icon type="save" /> Salva
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Query>
        </Col>
      </Row>
    );
  }
}

export default compose(
  connect(),
  withRouter,
  graphql(EDIT_VIDEO, {
    name: 'editVideo',
  }),
  Form.create({ name: 'edit-video' })
)(EditVideoForm);
