import React from 'react';
import { connect } from 'react-redux';

function mapStateToProps({ user }) {
  return user;
}

const withState = connect(
  mapStateToProps
);
/**
 * HOC that adds the user to the props
 * @returns {function(WrappedComponent)}
 */

function withUser(WrappedComponent) {
  const ComponentWithState = withState(WrappedComponent);

  function WithUser(props) {
    return (
      <ComponentWithState {...props} />
    );
  }
  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  WithUser.displayName = `withUser(${wrappedComponentName})`;
  return WithUser;
}

export default withUser;
