import { createStore } from 'redux';
import { persistCombineReducers } from 'redux-persist';
import localForage from 'localforage';
// import { composeWithDevTools } from 'redux-devtools-extension';
import { devToolsEnhancer } from 'redux-devtools-extension';

import reducers from './reducers';

const config = {
  key: 'primary',
  storage: localForage,
  // blacklist: ['checklist'], // Don't persist
  debug: true,
};
const reducer = persistCombineReducers(config, reducers);

/**
 * Redux store
 */
const store = createStore(reducer, /* preloadedState, */ devToolsEnhancer());

export default store;
