import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000
});

function show({ type = 'success', title = 'Messaggio mancante...', ...rest }) {
  Toast.fire({ type, title, ...rest });
}

export default show;

