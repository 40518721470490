import React from 'react';
import { Query } from 'react-apollo';

import Downloads from './components/Downloads';
import LIST_DOWNLOADS from './Download.query.graphql';


function Download() {
  return (
    <React.Fragment>
      <Query query={LIST_DOWNLOADS}>
        {({ loading, error, refetch, data }) => {
          return (
            <React.Fragment>
              <Downloads
                data={data}
                loading={loading}
                refetch={refetch}
                error={error}
              />
            </React.Fragment>
          );
        }}
      </Query>
    </React.Fragment>
  );
}

Download.defaultProps = {};
Download.propTypes = {};
export default Download;
