import React from 'react';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { Layout, Typography } from 'antd';
import styled from 'styled-components';

import { withLayout } from '../../hocs';
import Sidebar from './components/Sidebar';
import UploadCatalogue from './components/UploadCatalogue';
import UploadCrc from './components/UploadCrc';
import UploadProducts from './components/UploadProducts';

const StyledLayout = styled(Layout)`
  margin: -24px;
  background: white;
  .sider {
    min-height: calc(100vh - 64px);
  }
  .content {
    margin: 0;
  }
`;

function Import() {
  const {type} = useParams();
  return (
    <StyledLayout>
      <Sidebar/>
      <Layout.Content className="content">
        <Typography.Title level={3}>
          {type === 'torsions' && <span>Giunti</span>}
          {type === 'trucks' && <span>Autocarri</span>}
          {type === 'cars' && <span>Automobili</span>}
          {type === 'tractors' && <span>Trattori</span>}
          {type === 'crc' && <span>Cross Reference Codes</span>}
          {type === 'products' && <span>Catalogo prodotti</span>}
        </Typography.Title>
        {type === 'torsions' && <UploadCatalogue type="EXCAVATOR" />}
        {type === 'trucks' && <UploadCatalogue type="TRUCK" />}
        {type === 'cars' && <UploadCatalogue type="CAR" />}
        {type === 'tractors' && <UploadCatalogue type="TRACTOR" />}
        {type === 'crc' && <UploadCrc />}
        {type === 'products' && <UploadProducts />}
      </Layout.Content>
    </StyledLayout>
  );
}

Import.defaultProps = {};
Import.propTypes = {};
export default compose(
  withLayout(),
)(Import);
