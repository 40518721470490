import React from 'react';
import { compose } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { Form, Icon, Button, Divider, Row, Col, Checkbox } from 'antd';
import { graphql } from 'react-apollo';

import ADD_BACKGROUND from './CreateBackground.mutation.graphql';
import toast from '../../../../libs/toast';

class CreateBackgroundForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: undefined,
    };
  }
  async handleSubmit(e) {
    e.preventDefault();
    const { form, addBackground, history } = this.props;
    form.validateFieldsAndScroll(async (err, configuration) => {
      if (!err) {
        try {
          await addBackground({ variables: { configuration: { ...configuration, key: 'background', image: this.state.file } } });
          toast({ title: 'Dati salvati correttamente' });
          history.push('/administration/backgrounds/list');
        } catch (error) {
          toast({ type: 'error', title: 'Errore durante il salvataggio dei dati' });
          console.error(error);
        }
      }
    });
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row>
        <Col lg={{ span: 8, offset: 8 }} md={24}>
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <Form.Item label="Immagine">
              {getFieldDecorator('value', {
                rules: [
                  { required: true, message: 'Campo obbligatorio', }
                ],
              })(
                <input
                  type="file"
                  onChange={({
                    target: {
                      validity,
                      files: [file],
                    },
                  }) => validity.valid && this.setState({ file })}
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('meta.enabled', { valuePropName: 'checked', initialValue: true })(
                <Checkbox>Abilitato</Checkbox>
              )}
            </Form.Item>
            <Divider />
            <div className="operations">
              <Button htmlType="button" size="large">
                <Link to="/administration/backgrounds/list">
                  <Icon type="arrow-left" /> Indietro
                </Link>
              </Button>
              <Button type="primary" htmlType="submit" size="large">
                <Icon type="save" /> Salva
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    );
  }
}

export default compose(
  withRouter,
  graphql(ADD_BACKGROUND, {
    name: 'addBackground',
  }),
  Form.create({ name: 'add-background' })
)(CreateBackgroundForm);
